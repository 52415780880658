import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { CustomSelect } from "components/CustomSelect";

const ClientSelect = ({
  clients,
  normalizeClientsOptions,
  selectedClient,
  setFieldValue,
  defaultValue,
  ...props
}) => {
  useEffect(() => {
    if (selectedClient) {
      console.log("selectedClient", selectedClient);
      setFieldValue("clientId", selectedClient.id);
    }
  }, [selectedClient, setFieldValue]);

  return (
    <Field
      as={CustomSelect}
      name="clientId"
      options={normalizeClientsOptions(clients)}
      defaultValue={defaultValue}
    />
  );
};

export default ClientSelect;
