import { useState } from "react";
import {
  Box,
  Textarea,
  Button,
  Switch,
  useColorMode,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  useColorModeValue,
} from "@chakra-ui/react";
import styled from "styled-components";
import { marked } from "marked";
import { useField, ErrorMessage } from "formik";
import ErrorMessageText from "components/ErrorMessageText";

const MarkdownEditor = ({
  className,
  label,
  value,
  disabled,
  isMoney,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(props);
  const hasError = !!(meta.touched && meta.error);

  //   const [value, setValue] = useState("");
  const [preview, setPreview] = useState(false);
  const { colorMode } = useColorMode();

  const handleHeaderChange = (header) => {
    const textarea = document.getElementById(field.name);
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;

    if (startPos !== endPos) {
      const selectedText = value.substring(startPos, endPos);
      const newText = `${value.substring(
        0,
        startPos
      )}${header} ${selectedText}${value.substring(endPos)}`;
      setValue(newText);
    } else {
      setValue((val) => `${val}\n${header} `);
    }
  };

  const handleButtonClick = (tag) => {
    const textarea = document.getElementById(field.name);
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;

    if (startPos !== endPos) {
      const selectedText = value.substring(startPos, endPos);
      const newText = `${value.substring(
        0,
        startPos
      )}${tag}${selectedText}${tag}${value.substring(endPos)}`;
      setValue(newText);
    } else {
      setValue((val) => `${val}\n${tag}`);
    }
  };

  const handleButtonBoldClick = (tag) => {
    const textarea = document.getElementById(field.name);
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;

    if (startPos !== endPos) {
      const selectedText = value.substring(startPos, endPos);
      const newText = `${value.substring(
        0,
        startPos
      )}${tag}${selectedText}${tag}${value.substring(endPos)}`;
      setValue(newText);
    } else {
      setValue((val) => `${val}\n${tag}`);
    }
  };

  const handleButtonListClick = (tag) => {
    const textarea = document.getElementById(field.name);
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;

    if (startPos !== endPos) {
      const selectedText = value.substring(startPos, endPos);
      const lines = selectedText.split("\n");
      const listText = lines.map((line) => `- ${line}`).join("\n");
      const newText = `${value.substring(
        0,
        startPos
      )}${listText}${value.substring(endPos)}`;
      setValue(newText);
    } else {
      setValue((val) => `${val}\n${tag}`);
    }
  };
  const handleSwitchChange = () => {
    setPreview((prev) => !prev);
  };

  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.800")}
      padding="1rem"
      border="1px solid"
      borderColor={useColorModeValue("gray.200", "gray.700")}
      borderRadius="md"
    >
      <ButtonGroup>
        <Button onClick={() => handleButtonBoldClick("**")}>Negritas</Button>
        <Menu>
          <MenuButton as={Button}>Encabezado</MenuButton>
          <MenuList>
            <MenuItem onClick={() => handleHeaderChange("#")}>
              Encabezado 1
            </MenuItem>
            <MenuItem onClick={() => handleHeaderChange("##")}>
              Encabezado 2
            </MenuItem>
            <MenuItem onClick={() => handleHeaderChange("###")}>
              Encabezado 3
            </MenuItem>
          </MenuList>
        </Menu>

        <Button onClick={() => handleButtonListClick("- ")}>Lista</Button>
        <Switch
          mt={2}
          colorScheme={colorMode === "light" ? "teal" : "green"}
          onChange={handleSwitchChange}
        >
          Vista previa
        </Switch>
      </ButtonGroup>

      {preview ? (
        <PreviewBox
          dangerouslySetInnerHTML={{
            __html: marked(value || "").replace(/[\r\n]{3,}/g, "<br /><br />"),
          }}
        />
      ) : (
        <Textarea
          id={field.name}
          size={preview ? "sm" : "lg"}
          {...field}
          {...props}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Escribe aquí en markdown"
          bg={colorMode === "light" ? "white" : "gray.700"}
          //   whiteSpace="pre-wrap"
        />
      )}
    </Box>
  );
};

const ButtonGroup = styled(Box)`
  display: flex;
  margin-bottom: 8px;
  & > button {
    margin-right: 8px;
  }
`;

const PreviewBox = styled(Box)`
  white-space: pre;
  line-break: strict;
  margin-top: 8px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.white};
  line-height: 1.5;
  padding: 1em;
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.3em;
  }
  h3 {
    font-size: 1.1em;
  }
  p {
    font-size: 1em;
  }
  ul {
    padding-left: 1.5em;
  }
  li {
    font-size: 1em;
  }
  strong {
    font-weight: bold;
  }
`;

export default MarkdownEditor;
