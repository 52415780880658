// BadgePart.js
import { Badge } from "@chakra-ui/react";
import { STATUS, TICKETS_STATUS_COLOR_SCHEME } from "constants/status";

export default function BadgePart({ status, ...props }) {
  const renderSwitchName = (status) => {
    switch (status) {
      case STATUS.INWORK:
        return "Trabajando";
      case STATUS.OPEN:
        return "Abierto";
      case STATUS.CLOSE:
        return "Finalizado";
      default:
        return "Pendiente";
    }
  };

  return (
    <Badge
      fontSize="sm"
      colorScheme={TICKETS_STATUS_COLOR_SCHEME[status]}
      textTransform="capitalize"
      borderRadius={"md"}
      br={2}
      p={1}
      mb={2}
      {...props}
    >
      {renderSwitchName(status)}
    </Badge>
  );
}
