import React, { useEffect, useState } from "react";
import Sidebar from "components/layouts/Sidebar";
import Card from "components/CardBox/Card";
import CardBody from "components/CardBox/CardBody";
import CardHeader from "components/CardBox/CardHeader";
import useConnect from "./connect";
import { useNavigate } from "react-router-dom";
import {
  useColorModeValue,
  Flex,
  SimpleGrid,
  Box,
  Button,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Alert,
  AlertIcon,
  IconButton,
  Grid,
  Input,
  VStack,
  FormControl,
  Heading,
} from "@chakra-ui/react";

import CardPart from "components/Cards/Tickets";

export default function Tickets() {
  const navigate = useNavigate();
  const {
    textColor,
    tickets,
    fetchMoreTickets,
    hasMore,
    setSearchTerm,
    handleRemoveTicket,
  } = useConnect();
  const [isLoading, setIsLoading] = useState(false);

  const handleLoadMore = () => {
    setIsLoading(true);
    fetchMoreTickets()
      .then(() => setIsLoading(false))
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching more tickets:", error);
      });
  };

  const handleSearch = async (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setIsLoading(true);
    await fetchMoreTickets(true);
    setIsLoading(false);
  };

  useEffect(() => {
    async function initialFetch() {
      setIsLoading(true);
      await fetchMoreTickets(true);
      setIsLoading(false);
    }

    initialFetch();
  }, []);

  const handleCreate = () => {
    navigate("/tickets/add");
  };

  const refreshTickets = async () => {
    setIsLoading(true);
    await fetchMoreTickets(true);
    setIsLoading(false);
  };

  return (
    <Sidebar>
      <FormControl mb="4">
        <Flex justify="space-between" align="center" mb={10}>
          <Heading size="md">Partes</Heading>
          <Button colorScheme="brand" onClick={handleCreate}>
            Crear parte
          </Button>
        </Flex>
        <Input
          type="text"
          placeholder="Filtrar por nombre"
          onChange={handleSearch}
        />
      </FormControl>

      <Box>
        <Box variant="simple" color={textColor}>
          <SimpleGrid columns={1} spacing={"40px"}>
            {tickets &&
              tickets.length > 0 &&
              tickets.map((ticket, i) => (
                <CardPart
                  key={i}
                  data={ticket}
                  refresh={refreshTickets}
                  handleRemoveTicket={handleRemoveTicket}
                />
              ))}
          </SimpleGrid>
        </Box>
        {hasMore && (
          <VStack mt={5}>
            <Button
              isLoading={isLoading}
              loadingText="Cargando"
              onClick={handleLoadMore}
            >
              Cargar más
            </Button>
          </VStack>
        )}
      </Box>
    </Sidebar>
  );
}
