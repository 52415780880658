// import

import { FiHome, FiTool, FiUser, FiUsers } from "react-icons/fi";

var dashRoutes = [
  // {
  //   path: "/home",
  //   name: "Home",
  //   icon: <FiHome color="inherit" />,
  // },
  {
    path: "/tickets",
    name: "Partes",
    icon: <FiTool color="inherit" />,
  },
  {
    path: "/clientes",
    name: "Clientes",
    icon: <FiUsers color="inherit" />,
  },
  {
    path: "/viviendas",
    name: "Viviendas",
    icon: <FiHome color="inherit" />,
  },
  {
    path: "/operarios",
    name: "Operarios",
    icon: <FiUser color="inherit" />,
  },

  {
    path: "/admin",
    name: "Administradores",
    icon: <FiUsers color="inherit" />,
  },
];

export const HOME = "/home";
export const SIGN_IN = "/";
export const LOGIN = "/";

export default dashRoutes;
