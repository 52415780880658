// ImageGallery.js
import React, { useState } from "react";
import { Grid, useColorModeValue } from "@chakra-ui/react";
import ImageCard from "components/ImageCard";
import ImageModal from "components/ImageModal";

const ImageGallery = ({ images, deleteImageFn, parentId }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (index) => {
    setSelectedImageIndex(index);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const gridBg = useColorModeValue("white", "gray.700");

  return (
    <Grid
      templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
      gap={4}
      bg={gridBg}
      p={3}
      borderRadius="md"
      w={{ base: "100%", md: "80%" }}
    >
      {images.map((image, index) => (
        <ImageCard
          key={index}
          image={image}
          onClick={() => handleOpenModal(index)}
        />
      ))}
      {modalOpen && (
        <ImageModal
          images={images}
          index={selectedImageIndex}
          isOpen={modalOpen}
          onClose={handleCloseModal}
        />
      )}
    </Grid>
  );
};

export default ImageGallery;
