import { db } from "lib/firebase";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";

export const getTicketsByClient = async (clientId) => {
  if (!clientId) return;

  try {
    const ticketsRef = collection(db, "tickets");
    const q = query(ticketsRef, where("clientId", "==", clientId));
    const querySnapshot = await getDocs(q);

    const clientTickets = await Promise.all(
      querySnapshot.docs.map(async (ticketDoc) => {
        const ticketData = ticketDoc.data();

        const clienteDoc = await getDoc(
          doc(db, "clientes", ticketData.clientId)
        );
        const viviendaDoc = await getDoc(
          doc(db, "viviendas", ticketData.viviendaId)
        );
        const operarioDoc = await getDoc(
          doc(db, "clientes", ticketData.operarioId)
        );

        return {
          id: ticketDoc.id,
          ...ticketData,
          cliente: {
            id: clienteDoc.id,
            ...clienteDoc.data(),
          },
          vivienda: {
            id: viviendaDoc.id,
            ...viviendaDoc.data(),
          },
          operario: {
            id: operarioDoc.id,
            ...operarioDoc.data(),
          },
        };
      })
    );

    console.log("Tickets del cliente:", clientTickets);
    return clientTickets;
  } catch (error) {
    console.error("Error al obtener los tickets del cliente:", error);
  }
};
