import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { initialValues, validationSchema } from "./constants";
import { normalizeSingleTicket } from "model/tickets";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getDocumentById } from "lib/firebase";
import { useToast } from "@chakra-ui/react";
const useConnect = () => {
  const db = getFirestore(initializeApp);
  const navigate = useNavigate();
  const { id } = useParams();

  const toast = useToast();

  const [part, setPart] = useState({});
  const [isLoadingPart, setIsLoadingPart] = useState(false);

  useEffect(() => {
    const getPart = async () => {
      setIsLoadingPart(true);
      try {
        const part = await getDocumentById("tickets", id);
        setPart(normalizeSingleTicket(part));
      } catch (error) {
        console.log("error", error);
      }
      setIsLoadingPart(false);
    };
    getPart();
  }, [id]);

  const [initialValuesSchema, setIinitialValuesSchema] =
    useState(initialValues);

  useEffect(() => {
    if (part) {
      setIinitialValuesSchema(part);
    }
  }, [part]);

  const handleSubmit = useCallback(async (values, { setErrors }) => {
    try {
      await updateDoc(
        doc(db, "tickets", id),
        {
          ...values,
          // createdAt: new Date(values.createdAt),
          updatedAt: serverTimestamp(),
        }
        // { merge: true }
      );

      toast({
        title: "ticket actualizado con éxito",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Redirige al usuario al panel de control o a la página de inicio
    } catch (error) {
      toast({
        title: "Error al actualizar al ticket",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, []);

  return {
    handleSubmit,
    initialValues,
    initialValuesSchema,
    validationSchema,
    isLoadingPart,
    part,
    id,
  };
};

export default useConnect;
