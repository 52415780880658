import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import { getFirestore, deleteDoc } from "firebase/firestore";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { initializeApp } from "firebase/app";

const useConnect = () => {
  const { id } = useParams();
  const db = getFirestore(initializeApp);
  const storage = getStorage();
  const [ticket, setTicket] = useState({});
  const [needRefreshIncidents, setNeedRefreshIncidents] = useState(false);
  const [needRefreshTicket, setNeedRefreshTicket] = useState(false);

  const refreshTicket = () => {
    console.log("refreshing ticket");
    setNeedRefreshTicket(true);

    setTimeout(() => {
      setNeedRefreshTicket(false);
    }, 1000);
  };

  useEffect(() => {
    const loadTicket = async () => {
      try {
        const ticketDocRef = doc(db, "tickets", id);
        const ticketDocSnap = await getDoc(ticketDocRef);
        const ticketData = ticketDocSnap.data();

        // Load related documents in parallel using Promise.all
        const [
          clienteDocSnap,
          viviendaDocSnap,
          serviceDocSnap,
          operarioDocSnap,
        ] = await Promise.all([
          getDoc(doc(db, "clientes", ticketData.clientId)),
          getDoc(doc(db, "viviendas", ticketData.viviendaId)),
          getDoc(doc(db, "services", ticketData.serviceId)),
          getDoc(doc(db, "clientes", ticketData.operarioId)),
        ]);

        const clienteData = clienteDocSnap.data();
        const viviendaData = viviendaDocSnap.data();
        const serviceData = serviceDocSnap.data();
        const operarioData = operarioDocSnap.data();

        setTicket({
          id: ticketDocSnap.id,
          ...ticketData,
          cliente: clienteData,
          vivienda: viviendaData,
          service: serviceData,
          operario: operarioData,
        });
      } catch (error) {
        console.error("Error obteniendo el ticket:", error);
      }
    };

    loadTicket();
  }, [db, id, needRefreshTicket]);

  const [isLoadingIncidents, setIsLoadingIncidents] = useState(false);
  const [incidents, setIncidents] = useState([]);
  useEffect(() => {
    const getIncidents = async (ticketId) => {
      setIsLoadingIncidents(true);
      try {
        const ticketsCollectionRef = collection(db, "incidencias");
        const q = query(
          ticketsCollectionRef,
          where("ticketId", "==", ticketId)
        );

        const incidentsQuerySnapshot = await getDocs(q);

        const incidentsList = incidentsQuerySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setIncidents(incidentsList);
      } catch (error) {
        console.log("error", error);
      }
      setIsLoadingIncidents(false);
    };
    if (ticket.id) {
      getIncidents(ticket.id);
    }
  }, [db, ticket.id, needRefreshIncidents]);

  const delteIncident = async ({ id, imagesURLs }) => {
    try {
      // Eliminar incidencia en la colección 'allIncidencias'
      const allIncidenciaRef = doc(db, "incidencias", id);
      await deleteDoc(allIncidenciaRef);
      setNeedRefreshIncidents(false);
      // Eliminar imágenes asociadas en Firebase Storage
      for (let i = 0; i < imagesURLs.length; i++) {
        const imageRef = ref(storage, `incidencias/${id}/${i}.jpg`);
        await deleteObject(imageRef);
      }
      setNeedRefreshIncidents(true);
      return { success: true };
    } catch (error) {
      console.error("Error al eliminar incidencia: ", error);
      return { success: false };
    }
  };

  return {
    ticket,
    isLoadingIncidents,
    incidents,
    delteIncident,
    needRefreshIncidents,
    setNeedRefreshIncidents,
    id,
    refreshTicket,
  };
};

export default useConnect;
