import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "config/firebase";
import Sidebar from "components/layouts/Sidebar";
import { useParams } from "react-router-dom";
const EditOperarioSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "El nombre debe tener al menos 2 caracteres")
    .required("El nombre es obligatorio"),
  email: Yup.string()
    .email("Correo electrónico inválido")
    .required("El correo electrónico es obligatorio"),
  phone: Yup.string()
    .matches(/^\+?\d{9,15}$/, "Número de teléfono inválido")
    .required("El teléfono es obligatorio"),
});

const EditOperario = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);

  const toast = useToast();

  useEffect(() => {
    const fetchUserData = async () => {
      const userDoc = await getDoc(doc(db, "clientes", id));
      setUserData(userDoc.data());
    };

    fetchUserData();
  }, [id]);

  const formik = useFormik({
    initialValues: userData || {
      name: "",
      email: "",
      phone: "",
    },
    enableReinitialize: true,
    validationSchema: EditOperarioSchema,
    onSubmit: async (values) => {
      try {
        await updateDoc(
          doc(db, "clientes", id),
          {
            name: values.name,
            email: values.email,
            phone: values.phone,
            updatedAt: serverTimestamp(),
          },
          { merge: true }
        );

        toast({
          title: "Usuario actualizado con éxito",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // Redirige al usuario al panel de control o a la página de inicio
      } catch (error) {
        toast({
          title: "Error al actualizar al usuario",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  return (
    <Sidebar>
      <Box>
        {userData && (
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4}>
              <FormControl
                id="name"
                isInvalid={formik.touched.name && formik.errors.name}
              >
                <FormLabel>Nombre</FormLabel>
                <Input type="text" {...formik.getFieldProps("name")} />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="email"
                isInvalid={formik.touched.email && formik.errors.email}
              >
                <FormLabel>Email</FormLabel>
                <Input type="email" {...formik.getFieldProps("email")} />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="phone"
                isInvalid={formik.touched.phone && formik.errors.phone}
              >
                <FormLabel>Teléfono</FormLabel>
                <Input type="tel" {...formik.getFieldProps("phone")} />
                <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
              </FormControl>
              <Button colorScheme="green" type="submit" w={{ base: "100%" }}>
                Guardar cambios
              </Button>
            </VStack>
          </form>
        )}
      </Box>
    </Sidebar>
  );
};

export default EditOperario;
