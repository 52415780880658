// create a compoonent to get all ticket list of a client

// Path: src/components/ClientTickestLists/index.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { normalizeSingleTicket } from "model/tickets";
import { getTicketsByClient } from "lib/request/getTicketsByClient";
import Card from "components/Cards/Tickets";
import { Box, Heading, Text, useColorModeValue } from "@chakra-ui/react";

export default function ClientTickestLists({ selectedClient }) {
  const [tickets, setTickets] = useState();
  useEffect(() => {
    if (selectedClient) {
      getTicketsByClient(selectedClient.id).then((tickets) => {
        setTickets(tickets);
      });
    }
  }, [selectedClient]);

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Box>
      <Text fontSize="xl" color={textColor} fontWeight="bold">
        Partes del cliente
      </Text>
      {tickets &&
        tickets.length > 0 &&
        tickets.map((ticket) => <Card key={ticket.id} data={ticket} />)}
    </Box>
  );
}
