import React, { useState } from "react";
import { Form, Formik, Field } from "formik";
import useConnect from "./connect";
import { initialValues, validationSchema } from "./constants";
import Input from "components/Input";
import {
  Flex,
  Heading,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  Text,
  useColorModeValue,
  FormLabel,
  Switch,
  chakra,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import signInImage from "assets/images/signInImage.webp";

const Login = () => {
  const { onsSubmit, errorMessage } = useConnect();
  const [showPassword, setShowPassword] = useState(false);

  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");

  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onsSubmit}
          >
            {({ isSubmitting }) => (
              <Form noValidate>
                <Flex
                  direction="column"
                  w="100%"
                  background="transparent"
                  p="48px"
                  mt={{ md: "150px", lg: "80px" }}
                >
                  <Heading color={titleColor} fontSize="32px" mb="10px">
                    Bienvenido a Zahara Servicios
                  </Heading>
                  <Text
                    mb="36px"
                    ms="4px"
                    color={textColor}
                    fontWeight="bold"
                    fontSize="14px"
                  >
                    Introduce tu email y contraseña para acceder
                  </Text>
                  <FormControl>
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Email
                    </FormLabel>

                    <Field
                      as={Input}
                      label="Email"
                      name="email"
                      type="email"
                      placeholder="Dirección de email"
                      borderRadius="15px"
                      mb="24px"
                      fontSize="sm"
                      size="lg"
                    />
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Password
                    </FormLabel>
                    <Field
                      as={Input}
                      label="Password"
                      name="password"
                      placeholder="Contraseña"
                      borderRadius="15px"
                      mb="24px"
                      fontSize="sm"
                      size="lg"
                      type={showPassword ? "text" : "password"}
                    />

                    {errorMessage?.message ? (
                      <FormHelperText
                        color="red.500"
                        fontSize="sm"
                        fontWeight="normal"
                        mb="24px"
                      >
                        {errorMessage.message}
                      </FormHelperText>
                    ) : null}
                    <Button
                      fontSize="10px"
                      type="submit"
                      bg="teal.300"
                      w="100%"
                      h="45"
                      mb="20px"
                      color="white"
                      mt="20px"
                      _hover={{
                        bg: "teal.200",
                      }}
                      _active={{
                        bg: "teal.400",
                      }}
                    >
                      INICIAR SESIÓN
                    </Button>
                  </FormControl>
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    maxW="100%"
                    mt="0px"
                  >
                    <Text color={textColor} fontWeight="medium">
                      Powered by
                      <Link
                        color={titleColor}
                        as="span"
                        ms="5px"
                        fontWeight="bold"
                        href="https://www.zaharaservicios.com/"
                      >
                        Zahara Servicios
                      </Link>
                    </Text>
                  </Flex>
                </Flex>
              </Form>
            )}
          </Formik>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Login;
