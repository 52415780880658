// ImageCard.js
import React from "react";
import { Box, Image, IconButton, useColorModeValue } from "@chakra-ui/react";
import { FiTrash } from "react-icons/fi";

const ImageCard = ({ id, image, onDelete, onClick, parentId }) => {
  const cardBg = useColorModeValue("white", "gray.700");

  return (
    <Box position="relative" onClick={onClick}>
      <Image
        src={image}
        alt={"Foto incidencia"}
        objectFit="cover"
        borderRadius="md"
        cursor={"pointer"}
      />
      {/* <IconButton
        icon={<FiTrash />}
        aria-label="Eliminar imagen"
        position="absolute"
        top="0"
        right="0"
        variant="ghost"
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          onDelete(parentId, image);
        }}
      /> */}
    </Box>
  );
};

export default ImageCard;
