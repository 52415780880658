import * as React from "react";
import Select from "react-select";
import styled from "@emotion/styled";
import { Box, Flex } from "@chakra-ui/react";
import { useField, ErrorMessage } from "formik";
import ErrorMessageText from "components/ErrorMessageText";
import chroma from "chroma-js";

export const CustomSelect = ({ ...props }) => {
  const [field, meta, { setValue }] = useField(props);
  const hasError = !!(meta.touched && meta.error);
  const { options } = props;

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      with: "100%",
      padding: "0.5rem",
      backgroundColor: "white",
      borderColor: "#cbd5e0",
      ":hover": { borderColor: "#3CA8E0" },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = chroma("#3CA8E0");
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#3CA8E0"
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled ? "#ccc" : isSelected ? "white" : "#3CA8E0",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (isSelected ? "#3CA8E0" : color.alpha(0.3).css()),
        },
      };
    },
    input: (styles) => ({ ...styles }),
    multiValue: (styles) => {
      const color = chroma("#3CA8E0");
      return {
        ...styles,
        backgroundColor: color.alpha(0.2).css(),
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#3CA8E0",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#3CA8E0",
      ":hover": {
        backgroundColor: "#3CA8E0",
        color: "white",
      },
    }),
  };

  return (
    <Box>
      <label htmlFor={field.name}>{props.label}</label>
      <Select
        styles={colourStyles}
        {...field}
        {...props}
        options={options}
        value={
          options ? options.find((option) => option.value === field.value) : ""
        }
        onChange={(option) => setValue(option.value)}
      />
      {hasError && (
        <ErrorMessage
          component={ErrorMessageText}
          color="red.500"
          fontSize="sm"
          name={field.name}
          mt={1}
        />
      )}
    </Box>
  );
};
