export const normalizeOwner = (data) => {
  return {
    id: data.id,
    username: data.attributes.username,
    email: data.attributes.email,
  };
};

export const normalizeClient = (data) => {
  return {
    id: data.id,
    username: data.attributes.username,
    email: data.attributes.email,
  };
};

export const normalizeProperty = (data) => {
  return {
    id: data.id,
    title: data.attributes.title,
    finishAt: data.attributes.finishAt,
    createdAt: data.attributes.createdAt,
    updatedAt: data.attributes.updatedAt,
  };
};

export const normalizeService = (data) => {
  return {
    id: data.id,
    title: data.attributes.title,
  };
};

export const normalizePart = (data) => {
  return {
    id: data.id,
    title: data.attributes.title,
    description: data.attributes.description,
    status: data.attributes.status,
    finishAt: data.attributes.finishAt,
    createdAt: data.attributes.createdAt,
    updatedAt: data.attributes.updatedAt,
    owner: normalizeOwner(data.attributes.owner.data),
    service: normalizeService(data.attributes.service.data),
    client: normalizeClient(data.attributes.client.data),
    property: data.attributes.properties.data.map((property) =>
      normalizeProperty(property)
    ),
  };
};

export const normalizeParts = (data) => {
  return {
    data: data.data.map((part) => normalizePart(part)),
  };
};

export const normalizeSingleTicket = (data) => {
  return {
    id: data.id,
    title: data.title,
    description: data.description,
    status: data.status,
    createdAt: data.createdAt.toDate(),
    clientId: data.clientId,
    viviendaId: data.viviendaId,
    operarioId: data.operarioId,
    serviceId: data.serviceId,
  };
};
