// src/containers/Main/Tickets/Add/index.js
import React, { useState } from "react";

import Sidebar from "components/layouts/Sidebar";
import { Form, Formik, Field, ErrorMessage } from "formik";
import {
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Button,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/CardBox/Card";
import CardBody from "components/CardBox/CardBody";
import CardHeader from "components/CardBox/CardHeader";
import ClientForm from "components/ClientForm";
import Input from "components/Input";
import useConnect from "./connect";

import Textarea from "components/MarkdownEditor";
import { CustomSelect } from "components/CustomSelect";
import { CustomDayPicker } from "components/CustomDayPicker";
import { formatFirestoreDate } from "utils/formatDate";
import CreateOrUpdateTicketForm from "components/Form/CreateOrUpdateTicketForm";
import ClientTickestLists from "components/ClientTickestLists";

export default function AddPart() {
  const [selectedClient, setSelectedClient] = useState(null);

  const handleClientChange = (clientData) => {
    setSelectedClient(clientData);
  };

  const textColor = useColorModeValue("gray.700", "white");
  const { handleSubmit } = useConnect();

  return (
    <div>
      <Sidebar>
        <Heading as="h1" mb="20px">
          Nuevo Ticket
        </Heading>
        <Grid templateColumns={["1fr", null, "repeat(2, 1fr)"]} gap={10}>
          <Card>
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Configuración General
              </Text>
            </CardHeader>
            <CardBody>
              <CreateOrUpdateTicketForm
                handleSubmit={handleSubmit}
                selectedClient={selectedClient}
              />
            </CardBody>
          </Card>
          <Card>
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Cliente
              </Text>
            </CardHeader>
            <CardBody>
              <ClientForm onClientChange={handleClientChange} />
              {selectedClient && (
                <ClientTickestLists selectedClient={selectedClient} />
              )}
            </CardBody>
          </Card>
        </Grid>
      </Sidebar>
    </div>
  );
}
