import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import AddIncidenciaForm from "components/Form/AddIncidencia";

const AddIncidenciaModal = ({ ticketId, refresh = () => {} }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button colorScheme="blue" onClick={onOpen}>
        Añadir incidencia
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Añadir incidencia</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddIncidenciaForm
              ticketId={ticketId}
              onClose={onClose}
              refresh={refresh}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddIncidenciaModal;
