import { useState, useEffect } from "react";
import {
  VStack,
  Flex,
  Input,
  FormLabel,
  FormControl,
  Button,
  Heading,
} from "@chakra-ui/react";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  startAfter,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "config/firebase";
import OperariosListItem from "components/Cards/Operario";
import Sidebar from "components/layouts/Sidebar";
import { useNavigate } from "react-router-dom";

const UserList = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchUsers = async () => {
    const usersRef = collection(db, "clientes");
    let q;

    if (searchTerm) {
      q = query(
        usersRef,
        orderBy("name"),
        where("role", "==", "operario"),
        where("name", ">=", searchTerm),
        where("name", "<=", searchTerm + "\uf8ff"),
        limit(5)
      );
    } else {
      q = query(
        usersRef,
        orderBy("name"),
        where("role", "==", "operario"),
        limit(5)
      );
    }

    const data = await getDocs(q);
    const usersArray = [];
    data.forEach((doc) => {
      usersArray.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setUsers(usersArray);
    setLastDoc(data.docs[data.docs.length - 1]);
  };
  console.log(users);

  const fetchMoreUsers = async () => {
    const usersRef = collection(db, "clientes");
    let q = query(
      usersRef,
      orderBy("name"),
      where("role", "==", "operario"),
      where("name", ">=", searchTerm),
      where("name", "<=", searchTerm + "\uf8ff"),
      startAfter(lastDoc),
      limit(5)
    );
    const data = await getDocs(q);
    const usersArray = [];
    data.forEach((doc) => {
      usersArray.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setUsers([...users, ...usersArray]);
    setLastDoc(data.docs[data.docs.length - 1]);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchUsers();
    setIsLoading(false);
  }, [searchTerm]);

  const handleLoadMore = () => {
    fetchMoreUsers();
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleToggleUser = async (id, isActive) => {
    try {
      const userRef = doc(db, "clientes", id);
      await updateDoc(userRef, {
        isActive: !isActive,
      });
      setUsers(
        users.map((user) => {
          if (user.id === id) {
            return {
              ...user,
              isActive: !isActive,
            };
          }
          return user;
        })
      );
    } catch (error) {
      console.error("Error toggling user: ", error);
    }
  };

  const handleRemoveUser = async (id) => {
    try {
      const userRef = doc(db, "clientes", id);
      await deleteDoc(userRef);
      setUsers(users.filter((user) => user.id !== id));
    } catch (error) {
      console.error("Error removing user: ", error);
    }
  };

  const handleCreate = () => {
    navigate(`/operarios/add`);
  };

  return (
    <Sidebar>
      <FormControl mb="4">
        <Flex justify="space-between" align="center" mb={10}>
          <Heading size="md">Operarios</Heading>
          <Button colorScheme="brand" onClick={handleCreate}>
            Crear operario
          </Button>
        </Flex>
        <Input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Filtrar por nombre"
        />
      </FormControl>
      <VStack spacing={4}>
        {users.map((user) => (
          <OperariosListItem
            key={user.id}
            user={user}
            handleToggleUser={handleToggleUser}
            handleRemoveUser={handleRemoveUser}
          />
        ))}
        {lastDoc && (
          <Button isLoading={isLoading} onClick={handleLoadMore}>
            Cargar más
          </Button>
        )}
        {isLoading && (
          <Button
            isLoading={isLoading}
            loadingText="Cargando"
            onClick={handleLoadMore}
          >
            Cargar más
          </Button>
        )}
      </VStack>
    </Sidebar>
  );
};

export default UserList;
