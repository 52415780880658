import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useToast,
  Box,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { CheckIcon } from "@chakra-ui/icons";
import { doc, updateDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

const TicketStatusMenu = ({ ticketId, status, refreshTicket }) => {
  const toast = useToast();
  const db = getFirestore();

  const updateStatus = async (newStatus) => {
    if (newStatus === status) return;

    try {
      const ticketRef = doc(db, "tickets", ticketId);
      await updateDoc(ticketRef, { status: newStatus });

      toast({
        title: "Estado del ticket actualizado.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      refreshTicket();
    } catch (error) {
      console.error("Error actualizando el estado del ticket: ", error);
      toast({
        title: "Error actualizando el estado del ticket.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<FiMoreVertical />}
        aria-label="Opciones"
        variant="outline"
      />
      <MenuList>
        <MenuItem onClick={() => updateStatus("abierto")}>
          <Box
            as="span"
            color={status === "abierto" ? "green.500" : "gray.500"}
            mr={2}
          >
            {status === "abierto" && <CheckIcon />}
          </Box>
          Abierto
        </MenuItem>
        <MenuItem onClick={() => updateStatus("en proceso")}>
          <Box
            as="span"
            color={status === "en proceso" ? "yellow.500" : "gray.500"}
            mr={2}
          >
            {status === "en proceso" && <CheckIcon />}
          </Box>
          En proceso
        </MenuItem>
        <MenuItem onClick={() => updateStatus("finalizado")}>
          <Box
            as="span"
            color={status === "finalizado" ? "red.500" : "gray.500"}
            mr={2}
          >
            {status === "finalizado" && <CheckIcon />}
          </Box>
          Finalizado
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default TicketStatusMenu;
