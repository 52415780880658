// components/SignUp.js
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  doc,
  setDoc,
  serverTimestamp,
  addDoc,
  collection,
} from "firebase/firestore";
import { auth, db } from "config/firebase";
import * as Yup from "yup";
import Sidebar from "components/layouts/Sidebar";
import useConnect from "./connect";
import { CustomSelect } from "components/CustomSelect";
import { CustomSelectNormal } from "components/CustomSelectNormal";

const AddVivienda = () => {
  const { clients, normalizeClientsOptions } = useConnect();

  const toast = useToast();

  const SignUpSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "El nombre debe tener al menos 2 caracteres")
      .required("El nombre es obligatorio"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      clientId: "",
    },
    validationSchema: SignUpSchema,
    onSubmit: async (values) => {
      try {
        // Agregar un documento en la colección de usuarios con el ID del usuario
        await addDoc(collection(db, "viviendas"), {
          name: values.name,
          clientId: values.clientId,
          createdAt: serverTimestamp(),
        });

        // Mostrar un mensaje de éxito
        toast({
          title: "Vivienda registrado con éxito",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // Redirige al usuario a la página de inicio o al panel de control
      } catch (error) {
        // Mostrar un mensaje de error
        toast({
          title: "Error al registrar al vivienda",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  return (
    <Sidebar>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <VStack spacing={4}>
            <FormControl id="name">
              <FormLabel>Nombre</FormLabel>
              <Input type="text" {...formik.getFieldProps("name")} required />
            </FormControl>
            <FormControl mb={"10px"}>
              <FormLabel>Cliente</FormLabel>
              {clients && (
                <CustomSelectNormal
                  {...formik.getFieldProps("clientId")}
                  options={normalizeClientsOptions(clients)}
                  onChange={formik.setFieldValue}
                />
              )}
            </FormControl>

            <Button colorScheme="green" type="submit" w={{ base: "100%" }}>
              Crear vivienda
            </Button>
          </VStack>
        </form>
      </Box>
    </Sidebar>
  );
};

export default AddVivienda;
