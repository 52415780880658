// firebaseService.js
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  setDoc,
  Timestamp,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { app } from "config/firebase";
const db = getFirestore(app);
const storage = getStorage(app);

export const addIncidencia = async (ticketId, data, files) => {
  try {
    // Crear la incidencia
    const incidenciaRef = await addDoc(collection(db, `incidencias`), {
      titulo: data.titulo,
      descripcion: data.descripcion,
      fecha: Timestamp.now(),
      ticketId: ticketId,
    });

    // Subir las imágenes
    const imagesURLs = [];
    for (let i = 0; i < files.length; i++) {
      const storageRef = ref(
        storage,
        `incidencias/${incidenciaRef.id}/${i}.jpg`
      );
      await uploadBytes(storageRef, files[i]);
      const imageURL = await getDownloadURL(storageRef);
      imagesURLs.push(imageURL);
    }

    // Añadir las URLs de las imágenes a la incidencia
    await setDoc(incidenciaRef, { images: imagesURLs }, { merge: true });

    return { success: true };
  } catch (error) {
    console.error("Error al agregar incidencia: ", error);
    return { success: false };
  }
};

export const deleteIncidenciaImage = async (
  incidenciaId,
  imageUrl,
  refresh = () => {}
) => {
  try {
    // Obtener la incidencia
    const incidenciaRef = doc(db, "incidencias", incidenciaId);
    const incidenciaSnapshot = await getDoc(incidenciaRef);
    const incidenciaData = incidenciaSnapshot.data();

    if (!incidenciaData) {
      throw new Error("Incidencia no encontrada");
    }

    // Encontrar la imagen en el array de imágenes
    const imageIndex = incidenciaData.images.findIndex(
      (url) => url === imageUrl
    );

    if (imageIndex === -1) {
      throw new Error("Imagen no encontrada");
    }

    // Eliminar la imagen del storage
    const storageRef = ref(
      storage,
      `incidencias/${incidenciaId}/${imageIndex}.jpg`
    );
    await deleteObject(storageRef);

    // Eliminar la referencia de la imagen en el documento
    const newImages = incidenciaData.images.filter((url) => url !== imageUrl);
    await updateDoc(incidenciaRef, { images: newImages });

    console.log("Imagen eliminada con éxito");
    refresh();

    return { success: true };
  } catch (error) {
    console.error("Error al eliminar la imagen: ", error);
    return { success: false };
  }
};
