import React from "react";
import {
  VStack,
  HStack,
  ButtonGroup,
  Tag,
  Grid,
  Box,
  Flex,
  Button,
  Text,
  Link,
  Spacer,
  useBreakpointValue,
  Tooltip,
} from "@chakra-ui/react";
import { formatDistance } from "date-fns";
import { es } from "date-fns/locale";
import BadgePart from "components/BadgeParts";
import { FiTrash, FiEdit, FiEye } from "react-icons/fi";
import { Link as RouterLink } from "react-router-dom";
import ConfirmModal from "components/ConfirmModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TicketsStatusMenu from "components/TicketsStatusMenu";

export default function CardPart({ data, handleRemoveTicket, refresh }) {
  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const navigate = useNavigate();
  console.log(data);
  const handleViewClick = () => {
    navigate(`/tickets/${data.id}/view`);
  };

  const handleEditClick = () => {
    navigate(`/tickets/${data.id}/edit`);
  };

  const handleDeleteClick = () => {
    handleRemoveTicket(data.id);
    setIsConfirmOpen(false);
  };

  console.log("data", data);
  return (
    <Box borderWidth="1px" borderRadius="lg" padding="4" width="100%">
      <Box display="flex" flexDirection={["column", "column", "column", "row"]}>
        <Box>
          <Flex flexDirection={"row"} alignItems="center">
            <Text fontWeight="bold" mr={2}>
              {data.title}
            </Text>
          </Flex>

          <BadgePart status={data.status} />
          <Flex>
            <Flex direction="column" mr={2}>
              <Text fontSize="sm" color="gray.400" fontWeight="normal">
                Operario:
              </Text>
              <Link as={RouterLink} to={`/operarios/${data.operario?.id}/view`}>
                <Tag size="sm" colorScheme="blue" borderRadius="full">
                  {data.operario?.name}
                </Tag>
              </Link>
            </Flex>
            <Flex direction="column" mr={2}>
              <Text fontSize="sm" color="gray.400" fontWeight="normal">
                Inmueble:
              </Text>
              <Link as={RouterLink} to={`/viviendas/${data.vivienda?.id}/view`}>
                <Tag
                  size="sm"
                  // colorScheme="brand"
                  borderRadius="full"
                  variant={"outline"}
                >
                  {data.vivienda?.name}
                </Tag>
              </Link>
            </Flex>
            <Tooltip label={data.cliente?.phone} aria-label="Teléfono">
              <Flex direction="column">
                <Text fontSize="sm" color="gray.400" fontWeight="normal">
                  Cliente:
                </Text>
                <Link as={RouterLink} to={`/clientes/${data.cliente?.id}/view`}>
                  <Tag
                    size="sm"
                    // colorScheme="brand"
                    borderRadius="full"
                    variant={"outline"}
                  >
                    {data.cliente?.name}
                  </Tag>
                </Link>
              </Flex>
            </Tooltip>
          </Flex>
        </Box>
        <Spacer />
        <ButtonGroup size="sm" mt={[5, 5, 5, 0]}>
          <Button onClick={handleViewClick}>
            <FiEye />
          </Button>
          <Button onClick={handleEditClick}>
            <FiEdit />
          </Button>

          {handleRemoveTicket && (
            <Button colorScheme="red" onClick={() => setIsConfirmOpen(true)}>
              <FiTrash />
            </Button>
          )}
          <ConfirmModal
            isOpen={isConfirmOpen}
            onClose={() => setIsConfirmOpen(false)}
            onConfirm={handleDeleteClick}
            title="Eliminar usuario"
            message={`¿Estás seguro de que deseas eliminar a ${data.title}?`}
          />
          <TicketsStatusMenu
            status={data.status}
            ticketId={data.id}
            refreshTicket={refresh}
          />
        </ButtonGroup>
      </Box>
    </Box>
  );
}
