import * as yup from "yup";

export const initialValues = {
  title: "",
  description: "",
  status: "",
  createdAt: "",
  operarioId: "",
  clientId: "",
  viviendaId: "",
  serviceId: "",
};

export const validationSchema = yup.object().shape({
  title: yup.string().required("El título es requerido!"),
  clientId: yup.string().required("El cliente es requerido!"),
  viviendaId: yup.string().required("La vivienda es requerida!"),
  serviceId: yup.string().required("El servicio es requerido!"),
  operarioId: yup.string().required("El operario es requerido!"),
});
