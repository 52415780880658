import { useColorModeValue } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { getFirestore, deleteDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const useConnect = () => {
  const db = getFirestore(initializeApp);
  const [tickets, setTickets] = useState([]);
  const [lastTicket, setLastTicket] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchMoreTickets = async (reset = false) => {
    let ticketQuery;

    if (reset) {
      setLastTicket(null);
      setHasMore(true);
    }

    if (searchTerm !== "") {
      ticketQuery = query(
        collection(db, "tickets"),
        where("title", ">=", searchTerm),
        where("title", "<=", searchTerm + "\uf8ff"),
        orderBy("title"),
        limit(10)
      );
    } else {
      ticketQuery =
        lastTicket && !reset
          ? query(
              collection(db, "tickets"),
              orderBy("title"),
              limit(10),
              startAfter(lastTicket)
            )
          : query(collection(db, "tickets"), orderBy("title"), limit(10));
    }

    const querySnapshot = await getDocs(ticketQuery);

    if (!querySnapshot.empty) {
      const ticketsData = await Promise.all(
        querySnapshot.docs.map(async (docTemp) => {
          const clienteDocRef = doc(
            collection(db, "clientes"),
            docTemp.data().clientId
          );
          const clienteDoc = await getDoc(clienteDocRef);

          const operarioDocRef = doc(
            collection(db, "clientes"),
            docTemp.data().operarioId
          );
          const operarioDoc = await getDoc(operarioDocRef);

          const viviendaDocRef = doc(
            collection(db, "viviendas"),
            docTemp.data().viviendaId
          );
          const viviendaDoc = await getDoc(viviendaDocRef);
          console.log("docTemp", operarioDoc.data());
          return {
            id: docTemp.id,
            ...docTemp.data(),
            cliente: {
              id: clienteDoc.id,
              ...clienteDoc.data(),
            },
            vivienda: {
              id: viviendaDoc.id,
              ...viviendaDoc.data(),
            },
            operario: {
              id: operarioDoc.id,
              ...operarioDoc.data(),
            },
          };
        })
      );

      if (reset) {
        setTickets(ticketsData);
      } else {
        setTickets((prevTickets) => [...prevTickets, ...ticketsData]);
      }

      setLastTicket(querySnapshot.docs[querySnapshot.docs.length - 1]);
    } else {
      setHasMore(false);
    }
    return Promise.resolve(); // Añadir esta línea al final
  };

  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");

  const handleRemoveTicket = async (ticketId) => {
    const ticketDocRef = doc(collection(db, "tickets"), ticketId);
    await deleteDoc(ticketDocRef);
    setTickets((prevTickets) =>
      prevTickets.filter((ticket) => ticket.id !== ticketId)
    );
  };

  useEffect(() => {
    fetchMoreTickets(true);
  }, [searchTerm]);

  return {
    iconBoxInside,
    textColor,
    tickets,
    fetchMoreTickets,
    hasMore,
    setSearchTerm,
    handleRemoveTicket,
  };
};

export default useConnect;
