import * as React from "react";
import Select from "react-select";
import { Box } from "@chakra-ui/react";
import chroma from "chroma-js";

export const CustomSelectNormal = ({
  onChange,
  name,
  value,
  options,
  ...props
}) => {
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      with: "100%",
      padding: "0.5rem",
      backgroundColor: "white",
      borderColor: "#cbd5e0",
      ":hover": { borderColor: "#3CA8E0" },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = chroma("#3CA8E0");
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#3CA8E0"
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled ? "#ccc" : isSelected ? "white" : "#3CA8E0",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (isSelected ? "#3CA8E0" : color.alpha(0.3).css()),
        },
      };
    },
    input: (styles) => ({ ...styles }),
    multiValue: (styles) => {
      const color = chroma("#3CA8E0");
      return {
        ...styles,
        backgroundColor: color.alpha(0.2).css(),
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#3CA8E0",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#3CA8E0",
      ":hover": {
        backgroundColor: "#3CA8E0",
        color: "white",
      },
    }),
  };

  return (
    <Box>
      <label htmlFor={name}>{props.label}</label>
      <Select
        styles={colourStyles}
        options={options}
        value={options ? options.find((option) => option.value === value) : ""}
        onChange={(option) => onChange(name, option.value)}
        {...props}
      />
    </Box>
  );
};
