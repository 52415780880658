import React from "react";
import Sidebar from "components/layouts/Sidebar";
import { Form, Formik, Field, ErrorMessage } from "formik";
import {
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Button,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/CardBox/Card";
import CardBody from "components/CardBox/CardBody";
import CardHeader from "components/CardBox/CardHeader";

import Input from "components/Input";
import useConnect from "./connect";

import Textarea from "components/MarkdownEditor";
import { CustomSelect } from "components/CustomSelect";
import { CustomDayPicker } from "components/CustomDayPicker";
import { formatFirestoreDate } from "utils/formatDate";
import CreateOrUpdateTicketForm from "components/Form/CreateOrUpdateTicketForm";

export default function EditPart() {
  const textColor = useColorModeValue("gray.700", "white");
  const { handleSubmit, initialValuesSchema, id } = useConnect();

  return (
    <div>
      <Sidebar>
        <Heading as="h1" mb="20px">
          Editar Parte {id}
        </Heading>
        <Card>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Configuración General
            </Text>
          </CardHeader>
          <CardBody>
            <CreateOrUpdateTicketForm
              handleSubmit={handleSubmit}
              initialValues={initialValuesSchema}
            />
          </CardBody>
        </Card>
      </Sidebar>
    </div>
  );
}
