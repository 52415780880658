import React from "react";
import { ErrorMessage, useField } from "formik";
import {
  Box,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { STATUS, TICKETS_STATUS_COLOR_SCHEME } from "constants/status";

const TicketStatusForm = (props) => {
  const [field, meta, helpers] = useField(props);
  const hasError = !!(meta.touched && meta.error);

  return (
    <Box width="100%" p={4} borderWidth={1} borderRadius="md">
      <FormControl as="fieldset" isInvalid={hasError}>
        <FormLabel as="legend">Estado del ticket</FormLabel>
        <RadioGroup
          value={field.value}
          onChange={(value) => helpers.setValue(value)}
          name={field.name}
        >
          <Stack spacing={5} direction="row">
            <Radio
              value="abierto"
              colorScheme={TICKETS_STATUS_COLOR_SCHEME[STATUS.OPEN]}
            >
              Abierto
            </Radio>
            <Radio
              value="en proceso"
              colorScheme={TICKETS_STATUS_COLOR_SCHEME[STATUS.INWORK]}
            >
              En proceso
            </Radio>
            <Radio
              value="finalizado"
              colorScheme={TICKETS_STATUS_COLOR_SCHEME[STATUS.CLOSE]}
            >
              Finalizado
            </Radio>
          </Stack>
        </RadioGroup>
        {hasError && (
          <ErrorMessage
            component={Text}
            color="red.500"
            fontSize="sm"
            name={field.name}
            mt={1}
          />
        )}
      </FormControl>
    </Box>
  );
};

export default TicketStatusForm;
