import React, { useState } from "react";
import Sidebar from "components/layouts/Sidebar";
import {
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Badge,
  Text,
  useColorModeValue,
  Box,
  Flex,
} from "@chakra-ui/react";
import useConnect from "./connect";
import { formatFirestoreDate } from "utils/formatDate";
import AddIncidencia from "containers/Shared/Modals/AddIncidencia";
import IncidenciaCard from "components/Cards/Incidencias";
import TicketsStatusMenu from "components/TicketsStatusMenu";

export default function ViewPart() {
  const textColor = useColorModeValue("gray.700", "white");
  const {
    ticket,
    incidents,
    delteIncident,
    setNeedRefreshIncidents,
    refreshTicket,
  } = useConnect();

  return (
    <div>
      <Sidebar>
        <Heading as="h1" mb="20px">
          {ticket?.title}
        </Heading>

        <Flex alignItems="center">
          <strong>Estado:</strong>
          <Flex justifyContent="space-between" alignItems="center">
            <Badge
              borderRadius="md"
              mr={2}
              colorScheme={
                ticket.status === "abierto"
                  ? "green"
                  : ticket.status === "en proceso"
                  ? "yellow"
                  : "red"
              }
              fontSize={"xl"}
            >
              {ticket.status}
            </Badge>
            <TicketsStatusMenu
              ticketId={ticket.id}
              status={ticket.status}
              refreshTicket={refreshTicket}
            />
          </Flex>
        </Flex>

        <Box>
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <FormControl id="title">
              <FormLabel>Cliente</FormLabel>
              <Badge fontSize="1em" colorScheme="gray">
                {ticket?.cliente?.name}
              </Badge>
            </FormControl>
            <FormControl id="title">
              <FormLabel>Propiedad</FormLabel>
              <Badge fontSize="1em" colorScheme="green">
                {ticket?.vivienda?.name}
              </Badge>
            </FormControl>
            <FormControl id="title">
              <FormLabel>Servicio</FormLabel>
              <Badge fontSize="1em" colorScheme="blue">
                {ticket?.service?.name}
              </Badge>
            </FormControl>
            <FormControl id="title">
              <FormLabel>Servicio</FormLabel>
              <Badge fontSize="1em" colorScheme="blue">
                {ticket?.operario?.name}
              </Badge>
            </FormControl>
            {/* <FormControl id="title">
              <FormLabel>Fecha de creación</FormLabel>

              <Text>
                {ticket?.createdAt && formatFirestoreDate(ticket?.createdAt)}
              </Text>
            </FormControl> */}
          </Grid>
          <Box mb={10}>
            {incidents?.map((incident) => (
              <IncidenciaCard
                key={incident.id}
                incidencia={incident}
                refresh={refreshTicket}
                deleteFn={() =>
                  delteIncident({
                    id: incident.id,
                    imagesURLs: incident.images,
                  })
                }
              />
            ))}
          </Box>
          <AddIncidencia
            ticketId={ticket?.id}
            refresh={setNeedRefreshIncidents}
          />
        </Box>
      </Sidebar>
    </div>
  );
}
