// IncidenciaCard.js
import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  SimpleGrid,
  Flex,
  Button,
} from "@chakra-ui/react";
import ImageGallery from "components/ImageGallery";
import { deleteIncidenciaImage } from "services/incidences";

const IncidenciaCard = ({ incidencia, deleteFn = () => {}, refresh }) => {
  const { titulo, descripcion, fecha, images } = incidencia;

  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} mb={4} mt={4}>
      <VStack align="start">
        <Heading size="md">{titulo}</Heading>
        <Text>{descripcion}</Text>
        <HStack>
          <Text fontSize="sm" fontWeight="bold">
            Fecha:
          </Text>
          <Text fontSize="sm">
            {new Date(fecha.seconds * 1000).toLocaleString()}
          </Text>
        </HStack>
        {/* <SimpleGrid columns={{ base: 2, md: 3 }} spacing={2}> */}
        <ImageGallery images={images} />
        {/* </SimpleGrid> */}
        <Flex>
          <Button colorScheme="red" size="sm" onClick={deleteFn}>
            Borrar
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default IncidenciaCard;
