// src/components/Form/CreateOrUpdateTicketForm/index.js
import React, { useEffect } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import {
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Button,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import TicketStatusForm from "components/TicketsStatusForm";
import Input from "components/Input";

import Textarea from "components/MarkdownEditor";
import { CustomSelect } from "components/CustomSelect";
import { CustomDayPicker } from "components/CustomDayPicker";
import ClientSelect from "components/ClientSelect";
import useConnect from "./connect";
import {
  validationSchema,
  initialValues as initialValuesEmpty,
} from "./constants";

export default function CreateOrUpdateTicketForm({
  initialValues,
  handleSubmit,
  selectedClient,
}) {
  const {
    clients,
    properties,
    services,
    operarios,
    normalizeClientsOptions,
    normalizePropertiesOptions,
    normalizeServicesOptions,
    normalizeOperariosOptions,
  } = useConnect({ selectedClient });
  return (
    <Formik
      initialValues={initialValues || initialValuesEmpty}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, values, setFieldValue }) => {
        return (
          <Form noValidate>
            <Field as={TicketStatusForm} name="status" values={values} />

            <FormControl mb={"10px"}>
              <FormLabel>Título</FormLabel>
              <Field as={Input} name="title" type="text" />
            </FormControl>
            <FormControl mb={"10px"}>
              <FormLabel>Descripción</FormLabel>
              <Field as={Textarea} name="description" />
            </FormControl>

            <Grid templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} gap={10}>
              <FormControl mb={"10px"}>
                <FormLabel>Servicio</FormLabel>
                {services && (
                  <Field
                    as={CustomSelect}
                    name="serviceId"
                    options={normalizeServicesOptions(services)}
                    defaultValue={values.serviceId}
                  />
                )}
              </FormControl>
              <FormControl mb={"10px"}>
                <FormLabel>Operario</FormLabel>
                {operarios && (
                  <Field
                    as={CustomSelect}
                    name="operarioId"
                    options={normalizeOperariosOptions(operarios)}
                    defaultValue={values.operarioId}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} gap={10}>
              <FormControl mb={"10px"}>
                <FormLabel>Cliente</FormLabel>
                {clients && (
                  <ClientSelect
                    name="clientId"
                    clients={clients}
                    normalizeClientsOptions={normalizeClientsOptions}
                    selectedClient={selectedClient}
                    setFieldValue={setFieldValue}
                    defaultValue={
                      values.clientId ||
                      (selectedClient && {
                        value: selectedClient?.id,
                        label: selectedClient.name,
                      })
                    }
                  />
                  // <Field
                  //   as={CustomSelect}
                  //   name="clientId"
                  //   options={normalizeClientsOptions(clients)}
                  //   defaultValue={
                  //     values.clientId ||
                  //     (selectedClient && {
                  //       value: selectedClient.id,
                  //       label: selectedClient.name,
                  //     })
                  //   }
                  // />
                )}
              </FormControl>
              <FormControl mb={"10px"}>
                <FormLabel>Vivienda</FormLabel>
                {properties && (
                  <Field
                    as={CustomSelect}
                    name="viviendaId"
                    options={normalizePropertiesOptions(properties)}
                    defaultValue={values.viviendaId}
                  />
                )}
              </FormControl>
            </Grid>

            <Button
              type="submit"
              colorScheme="green"
              size="lg"
              disabled={isSubmitting}
              mt={"30px"}
              fontSize={"lg"}
              w={{ base: "100%" }}
            >
              Guardar cambios
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
