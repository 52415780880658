// ImageModal.js
import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Image,
  useColorModeValue,
  Box,
} from "@chakra-ui/react";

const ImageModal = ({ images, index, isOpen, onClose }) => {
  const modalBg = useColorModeValue("white", "gray.800");

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalCloseButton />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Image src={images[index]} alt={images[index]} />
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default ImageModal;
