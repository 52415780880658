import React from "react";
import { useFormik } from "formik";
import {
  FormControl,
  FormLabel,
  Button,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import {
  searchClient,
  saveOrUpdateClient,
  saveClient,
  updateClient,
} from "lib/firebase";
import { auth, db } from "config/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { createUser } from "lib/request/createUser";
const ClientForm = ({ onClientChange }) => {
  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      dni: "",
      name: "",
      lastName: "",
    },
    onSubmit: async (values) => {
      if (values.uid) {
        try {
          const updatedClientData = await updateClient(values);
          onClientChange(updatedClientData);
          toast({
            title: "Cliente actualizado con éxito",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Error al actualizar al cliente",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        try {
          // Registrar al usuario en Firebase
          const userResponse = await createUser(
            values.email,
            "ZaharaServicios123."
          );

          // Agregar un documento en la colección de usuarios con el ID del usuario
          const newUser = await setDoc(
            doc(db, "clientes", userResponse.localId),
            {
              uid: userResponse.localId,
              name: values.name,
              lastName: values.lastName,
              email: values.email,
              dni: values.dni,

              phone: values.phone,
              createdAt: serverTimestamp(),
            }
          );

          onClientChange(newUser);
          // Mostrar un mensaje de éxito
          toast({
            title: "Usuario registrado con éxito",
            status: "success",
            duration: 3000,
            isClosable: true,
          });

          // Redirige al usuario a la página de inicio o al panel de control
        } catch (error) {
          // Mostrar un mensaje de error
          toast({
            title: "Error al registrar al usuario",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    },
  });

  const handleSearch = async (field, value) => {
    const client = await searchClient(field, value);
    if (client) {
      formik.setValues(client);
      onClientChange(client);
    } else {
      formik.setFieldValue(field, value);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <VStack spacing={4} w="100%">
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={(e) => handleSearch("email", e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Teléfono</FormLabel>
          <Input
            type="tel"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={(e) => handleSearch("phone", e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>DNI</FormLabel>
          <Input
            type="text"
            name="dni"
            value={formik.values.dni}
            onChange={formik.handleChange}
            onBlur={(e) => handleSearch("dni", e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Nombre</FormLabel>
          <Input
            type="text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Apellido</FormLabel>
          <Input
            type="text"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
          />
        </FormControl>
        {/* Add other client fields if necessary */}
        <Button type="submit">Guardar cliente</Button>
      </VStack>
    </form>
  );
};

export default ClientForm;
