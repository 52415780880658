import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { initialValues, validationSchema } from "./constants";
import { normalizeSingleTicket } from "model/tickets";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getDocumentById } from "lib/firebase";
import { useToast } from "@chakra-ui/react";
const useConnect = () => {
  const db = getFirestore(initializeApp);
  // const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = useCallback(async (values, { setErrors }) => {
    try {
      await addDoc(collection(db, "tickets"), {
        ...values,
        createdAt: serverTimestamp(),
      });

      toast({
        title: "ticket creado con éxito",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Redirige al usuario al panel de control o a la página de inicio
    } catch (error) {
      toast({
        title: "Error al crear al ticket",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, []);

  return {
    handleSubmit,
  };
};

export default useConnect;
