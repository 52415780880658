// constants/status.js
export const STATUS = {
  INWORK: "en proceso",
  OPEN: "abierto",
  CLOSE: "finalizado",
};

export const TICKETS_STATUS_COLOR_SCHEME = {
  [STATUS.INWORK]: "yellow",
  [STATUS.OPEN]: "red",
  [STATUS.CLOSE]: "green",
};
