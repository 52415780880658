import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "containers/Guest/Login";
import Home from "containers/Main/Home";
import Parts from "containers/Main/Tickets";
import Error404 from "containers/Guest/Error404";
import ProtectedRoute from "containers/ProtectedRoute";
import EditPart from "containers/Main/Tickets/Edit";
import AddPart from "containers/Main/Tickets/Add";
import ViewPart from "containers/Main/Tickets/View";
import AddOperarios from "containers/Main/Operarios/Add";
import OperariosList from "containers/Main/Operarios";
import OperariosDetail from "containers/Main/Operarios/Details";
import EditOperario from "containers/Main/Operarios/Edit";
import AddCliente from "containers/Main/Clientes/Add";
import ClientesList from "containers/Main/Clientes";
import ClienteDetail from "containers/Main/Clientes/Details";
import EditCliente from "containers/Main/Clientes/Edit";
import ViviendasList from "containers/Main/Viviendas";
import AddVivienda from "containers/Main/Viviendas/Add";
import EditVivienda from "containers/Main/Viviendas/Edit";
import ViviendaDetail from "containers/Main/Viviendas/Details";
import AdminList from "containers/Main/Administradores";
import AddAdmin from "containers/Main/Administradores/Add";
import EditAdmin from "containers/Main/Administradores/Edit";
import AdminDetail from "containers/Main/Administradores/Details";

const Root = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        index
        path="/home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />

      <Route path="/tickets">
        <Route
          index
          path="/tickets"
          element={
            <ProtectedRoute>
              <Parts />
            </ProtectedRoute>
          }
        />
        <Route
          path="add"
          element={
            <ProtectedRoute>
              <AddPart />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/edit"
          element={
            <ProtectedRoute>
              <EditPart />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/view"
          element={
            <ProtectedRoute>
              <ViewPart />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="/operarios">
        <Route
          index
          path="/operarios"
          element={
            <ProtectedRoute>
              <OperariosList />
            </ProtectedRoute>
          }
        />
        <Route
          path="add"
          element={
            <ProtectedRoute>
              <AddOperarios />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/edit"
          element={
            <ProtectedRoute>
              <EditOperario />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/view"
          element={
            <ProtectedRoute>
              <OperariosDetail />
            </ProtectedRoute>
          }
        />
      </Route>

      {/* admin */}
      <Route path="/admin">
        <Route
          index
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminList />
            </ProtectedRoute>
          }
        />
        <Route
          path="add"
          element={
            <ProtectedRoute>
              <AddAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/edit"
          element={
            <ProtectedRoute>
              <EditAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/view"
          element={
            <ProtectedRoute>
              <AdminDetail />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="/clientes">
        <Route
          index
          path="/clientes"
          element={
            <ProtectedRoute>
              <ClientesList />
            </ProtectedRoute>
          }
        />
        <Route
          path="add"
          element={
            <ProtectedRoute>
              <AddCliente />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/edit"
          element={
            <ProtectedRoute>
              <EditCliente />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/view"
          element={
            <ProtectedRoute>
              <ClienteDetail />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="/viviendas">
        <Route
          index
          path="/viviendas"
          element={
            <ProtectedRoute>
              <ViviendasList />
            </ProtectedRoute>
          }
        />
        <Route
          path="add"
          element={
            <ProtectedRoute>
              <AddVivienda />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/edit"
          element={
            <ProtectedRoute>
              <EditVivienda />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/view"
          element={
            <ProtectedRoute>
              <ViviendaDetail />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default Root;
