import {
  doc,
  updateDoc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { tr } from "date-fns/locale";

export const db = getFirestore(initializeApp);
// función para editar un documento por su ID
export const updateDocumentById = async (
  collectionName,
  docId,
  dataToUpdate
) => {
  try {
    const docRef = doc(db, collectionName, docId); // referencia al documento a editar
    await updateDoc(docRef, dataToUpdate); // actualizar el documento
    console.log("Documento actualizado con éxito!");
  } catch (error) {
    console.error("Error al actualizar el documento: ", error);
  }
};

// updateDocumentById("tickets", "ABC123", { email: "nuevoemail@example.com" });

export const getDocumentById = async (collectionName, documentId) => {
  const docRef = doc(db, collectionName, documentId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return { id: docSnap.id, ...docSnap.data() };
  } else {
    throw new Error("No existe un documento con el ID proporcionado");
  }
};

export const getCollection = async (collectionName) => {
  const querySnapshot = await getDocs(collection(db, collectionName));
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export async function searchClient(field, value) {
  const clientsRef = collection(db, "clientes");
  const q = query(clientsRef, where(field, "==", value));
  const querySnapshot = await getDocs(q);

  let client = null;
  querySnapshot.forEach((doc) => {
    client = { id: doc.id, ...doc.data() };
  });

  return client;
}

export async function saveOrUpdateClient(clientData) {
  const clientRef = doc(db, "clientes", clientData.id || undefined);
  if (clientData.id) {
    console.log("update");
    await updateDoc(clientRef, clientData);
  } else {
    console.log("add");
    await setDoc(clientRef, clientData);
  }

  return { id: clientRef.id, ...clientData };
}

export async function saveClient(clientData) {
  const clientRef = doc(db, "clientes");
  await setDoc(clientRef, clientData);
  return { id: clientRef.id, ...clientData };
}

export async function updateClient(clientData) {
  const clientRef = doc(db, "clientes", clientData.id);
  await updateDoc(clientRef, clientData);
  return { id: clientRef.id, ...clientData };
}
