import { db } from "lib/firebase";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";

export const getUserById = (id) => {
  const userRef = doc(db, "clientes", id);
  return getDoc(userRef)
    .then((doc) => {
      if (doc.exists()) {
        return { id: doc.id, ...doc.data() };
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};
