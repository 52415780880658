/* eslint-disable react-hooks/exhaustive-deps */
// src/components/Form/CreateOrUpdateTicketForm/connect.js
import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { normalizeSingleTicket } from "model/tickets";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const useConnect = () => {
  const db = getFirestore(initializeApp);

  const [clients, setClients] = useState([]);
  useEffect(() => {
    const getClients = async () => {
      const clientsRef = collection(db, "clientes");
      const clientsSnapshot = await getDocs(clientsRef);
      const clientsList = clientsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClients(clientsList);
    };
    getClients();
  }, [db]);

  const normalizeClientsOptions = (clients) => {
    return clients?.map((client) => {
      return {
        label: client.name,
        value: client.id,
      };
    });
  };

  return {
    clients,
    normalizeClientsOptions,
  };
};

export default useConnect;
