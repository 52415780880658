// components/SignUp.js
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { auth, db } from "config/firebase";
import * as Yup from "yup";
import Sidebar from "components/layouts/Sidebar";

const AddAdministrador = () => {
  const toast = useToast();

  const SignUpSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "El nombre debe tener al menos 2 caracteres")
      .required("El nombre es obligatorio"),
    email: Yup.string()
      .email("Correo electrónico inválido")
      .required("El correo electrónico es obligatorio"),
    phone: Yup.string()
      .matches(/^\+?\d{9,15}$/, "Número de teléfono inválido")
      .required("El teléfono es obligatorio"),
    password: Yup.string()
      .min(6, "La contraseña debe tener al menos 6 caracteres")
      .required("La contraseña es obligatoria"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      role: "admin",
    },
    validationSchema: SignUpSchema,
    onSubmit: async (values) => {
      try {
        // Registrar al usuario en Firebase
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          values.email,
          values.password
        );
        const user = userCredential.user;

        // Agregar un documento en la colección de usuarios con el ID del usuario
        await setDoc(doc(db, "clientes", user.uid), {
          uid: user.uid,
          name: values.name,
          email: values.email,
          phone: values.phone,
          role: "admin",
          createdAt: serverTimestamp(),
        });

        // Mostrar un mensaje de éxito
        toast({
          title: "Usuario registrado con éxito",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // Redirige al usuario a la página de inicio o al panel de control
      } catch (error) {
        // Mostrar un mensaje de error
        toast({
          title: "Error al registrar al usuario",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  return (
    <Sidebar>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <VStack spacing={4}>
            <FormControl id="name">
              <FormLabel>Nombre</FormLabel>
              <Input type="text" {...formik.getFieldProps("name")} required />
            </FormControl>
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <Input type="email" {...formik.getFieldProps("email")} required />
            </FormControl>
            <FormControl id="phone">
              <FormLabel>Teléfono</FormLabel>
              <Input type="tel" {...formik.getFieldProps("phone")} required />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Contraseña</FormLabel>
              <Input
                type="password"
                {...formik.getFieldProps("password")}
                minLength={6}
                required
              />
            </FormControl>
            <Button colorScheme="green" type="submit" w={{ base: "100%" }}>
              Crear administrador
            </Button>
          </VStack>
        </form>
      </Box>
    </Sidebar>
  );
};

export default AddAdministrador;
