// AddIncidenciaForm.js
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { addIncidencia } from "services/incidences";

const AddIncidenciaForm = ({ ticketId, onClose, refresh }) => {
  const [files, setFiles] = useState([]);
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      titulo: "",
      descripcion: "",
    },
    validationSchema: Yup.object({
      titulo: Yup.string().required("Requerido"),
      descripcion: Yup.string().required("Requerido"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const result = await addIncidencia(ticketId, values, files);
      if (result.success) {
        toast({
          title: "Incidencia creada",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        resetForm();
        setFiles([]);
        refresh();
        onClose();
      } else {
        toast({
          title: "Error al crear incidencia",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
  });

  const handleFilesChange = (event) => {
    setFiles([...event.target.files]);
  };

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="titulo" isRequired>
            <FormLabel>Título</FormLabel>
            <Input type="text" {...formik.getFieldProps("titulo")} />
          </FormControl>
          <FormControl id="descripcion" isRequired>
            <FormLabel>Descripción</FormLabel>
            <Textarea {...formik.getFieldProps("descripcion")} />
          </FormControl>
          <FormControl id="fotografias">
            <FormLabel>Fotografías</FormLabel>
            <Input
              type="file"
              multiple
              accept="image/*"
              onChange={handleFilesChange}
            />
          </FormControl>
          <Button
            colorScheme="blue"
            type="submit"
            isLoading={formik.isSubmitting}
          >
            Enviar
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default AddIncidenciaForm;
