// components/OperariosDetail.js
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Heading, Text, Grid } from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import Sidebar from "components/layouts/Sidebar";
import ClientTickestLists from "components/ClientTickestLists";

const OperariosDetail = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const userDoc = await getDoc(doc(db, "clientes", id));
      setUser({ id: userDoc.id, ...userDoc.data() });
    };

    fetchUser();
  }, [id]);

  if (!user) {
    return <Text>Cargando usuario...</Text>;
  }

  return (
    <Sidebar>
      <Box>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <Box>
            <Heading as="h2">{user.name}</Heading>
            <Text>Email: {user.email}</Text>
            <Text>Teléfono: {user.phone}</Text>
            {user.isActive && <Text>Activo</Text>}
          </Box>
        </Grid>
        <ClientTickestLists selectedClient={user} />
      </Box>
    </Sidebar>
  );
};

export default OperariosDetail;
