/* eslint-disable react-hooks/exhaustive-deps */
// src/components/Form/CreateOrUpdateTicketForm/connect.js
import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { initialValues, validationSchema } from "./constants";
import { normalizeSingleTicket } from "model/tickets";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getDocumentById } from "lib/firebase";

const useConnect = ({ selectedClient }) => {
  const db = getFirestore(initializeApp);
  const navigate = useNavigate();
  const { id } = useParams();

  const [part, setPart] = useState({});
  const [isLoadingPart, setIsLoadingPart] = useState(false);

  useEffect(() => {
    const getPart = async () => {
      setIsLoadingPart(true);
      try {
        const part = await getDocumentById("tickets", id);
        setPart(normalizeSingleTicket(part));
      } catch (error) {
        console.log("error", error);
      }
      setIsLoadingPart(false);
    };
    getPart();
  }, [id]);

  const [clients, setClients] = useState([]);
  useEffect(() => {
    const getClients = async () => {
      const clientsRef = collection(db, "clientes");
      const clientsSnapshot = await getDocs(clientsRef);
      const clientsList = clientsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClients(clientsList);
    };
    getClients();

    if (selectedClient) {
      getClients();
    }
  }, [db, selectedClient]);

  const [operarios, setOperarios] = useState([]);
  useEffect(() => {
    const getOperarios = async () => {
      const operariosRef = collection(db, "operarios");
      const operariosSnapshot = await getDocs(operariosRef);
      const operariosList = operariosSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOperarios(operariosList);
    };
    getOperarios();
  }, [db]);

  const [properties, setProperties] = useState([]);
  useEffect(() => {
    const getProperties = async () => {
      const propertiesRef = collection(db, "viviendas");
      const propertiesSnapshot = await getDocs(propertiesRef);
      const propertiesList = propertiesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProperties(propertiesList);
    };
    getProperties();
  }, [db]);

  const [services, setServices] = useState([]);
  useEffect(() => {
    const getServices = async () => {
      const servicesRef = collection(db, "services");
      const servicesSnapshot = await getDocs(servicesRef);
      const servicesList = servicesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServices(servicesList);
    };
    getServices();
  }, [db]);

  const [initialValuesSchema, setIinitialValuesSchema] =
    useState(initialValues);

  useEffect(() => {
    if (part) {
      setIinitialValuesSchema(part);
    }
  }, [part]);

  const normalizeClientsOptions = (clients) => {
    return clients?.map((client) => {
      return {
        label: client.name,
        value: client.id,
      };
    });
  };

  const normalizeOperariosOptions = (operarios) => {
    return operarios.map((operario) => {
      return {
        label: operario.name,
        value: operario.id,
      };
    });
  };
  const normalizePropertiesOptions = (properties) => {
    return properties.map((property) => {
      return {
        label: `#${property.id} - ${property.name}`,
        value: property.id,
      };
    });
  };
  const normalizeServicesOptions = (services) => {
    return services.map((service) => {
      return {
        label: `${service.name}`,
        value: service.id,
      };
    });
  };

  return {
    initialValues,
    initialValuesSchema,
    validationSchema,
    isLoadingPart,
    part,
    clients,
    properties,
    services,
    operarios,
    id,
    normalizeClientsOptions,
    normalizePropertiesOptions,
    normalizeServicesOptions,
    normalizeOperariosOptions,
  };
};

export default useConnect;
