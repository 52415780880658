import * as yup from "yup";

export const initialValues = {
  title: "",
  description: "",
  status: "",
  finishAt: "",
  createdAt: "",
  updatedAt: "",
  owner: "",
  clientId: "",
  viviendaId: "",
  serviceId: "",
};

export const validationSchema = yup.object().shape({
  title: yup.string().required("El título es requerido!"),
});
